<script lang="ts">
	import { t } from '@woltair/translations-package-fe'
	import { getContext } from 'svelte'
	import { navigating } from '$app/stores'
	import Loading from './Loading.svelte'
	import { key } from '../form/key.js'
	interface Icon {
		src: string
		alt: string
	}
	export let href: String | null = null
	export let primary = false
	export let secondary = false
	export let black = false
	export let tertiary = false
	export let transparent = false
	export let blank = false
	export let icon: Icon | null = null
	export let iconPre: Icon | null = null
	export let disabled = false
	export let downloading = false
	export let margin = ''
	export let hideM = false
	export let minWidth = false
	export let maxWidth = false
	export let width = false
	export let round = false
	export let flip = false
	export let invisible = false
	export let text = ''
	export let type = 'button'
	export let slim = false
	export let noWrap = false
	export let hover = true
	export let columnGroup = false
	export let center = true
	export let cls = ''
	export let small = false
	export let tiny = false
	export let testId = null
	export let green = false
	export let noscroll = false
	export let loading = false
	export let success = false
	export let shadow = false
	export let target = null
	export function focus() {
		buttonThis.focus()
	}
	export function click() {
		if (!target) {
			buttonThis.click()
		}
	}

	let { promise } = getContext(key) || {}

	let buttonThis

	if (!primary && !secondary && !tertiary && !transparent && !blank && !black && !green && !success)
		primary = true

	delete $$props.testId
</script>

<svelte:element
	this={href ? 'a' : 'button'}
	class="ui-woltair-button {margin} disable-select {cls}"
	{href}
	{target}
	{type}
	data-testid={testId}
	data-sveltekit-noscroll={noscroll ? '' : 'off'}
	on:click
	bind:this={buttonThis}
	class:primary
	class:black
	class:secondary
	class:tertiary
	class:blank
	class:green
	style:min-width={minWidth}
	style:max-width={maxWidth}
	style:width
	class:column-group={columnGroup}
	class:disabled
	class:downloading
	class:flip
	class:hover
	class:invisible
	class:hide-mobile={hideM}
	class:whitespace-nowrap={noWrap}
	class:round
	class:transparent
	class:slim
	class:small
	class:tiny
	class:center
	class:success
	class:shadow
	{...$$restProps}
>
	{#if loading}
		{#if $navigating}
			<Loading />
		{:else}
			<span><span><slot /></span></span>
		{/if}
	{:else}
		{#await $promise}
			<Loading />
		{:then}
			{#if iconPre}
				<img
					src={iconPre.src}
					alt={t(iconPre.alt)}
					style:margin-right={iconPre?.marginRight || '10px'}
				/>
			{/if}
			{@html t(text)}
			<span><slot /></span>
			{#if icon}
				<img src={icon.src} alt={t(icon.alt)} />
			{/if}
		{:catch}
			{#if iconPre}
				<img
					src={iconPre.src}
					alt={t(iconPre.alt)}
					style:margin-right={iconPre?.marginRight || '10px'}
				/>
			{/if}
			{t(text)}
			<span><slot /></span>
			{#if icon}
				<img src={icon.src} alt={t(icon.alt)} />
			{/if}
		{/await}
		{#if icon?.srcHover}
			<img class="hoverState" src={icon.srcHover} alt={t(icon.alt)} />
		{/if}
	{/if}
</svelte:element>

<style lang="scss">
	.ui-woltair-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		font-size: 16px;
		line-height: 22px;
		border-radius: 999px;
		padding: 12px 25px;
		font-weight: 500;
		color: #fff;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border: none;
		outline: none;
		transition: 0.4s;
		gap: 10px;

		&.column-group {
			margin-bottom: 5px;
		}

		&.tiny {
			font-size: 12px;
			padding: 0.8rem 1.75rem;
		}
		&.slim {
			padding: 5px 20px;
		}

		&.small {
			font-size: 14px;
			padding: 10px 15px;
		}

		&.whitespace-nowrap {
			white-space: nowrap !important;
		}

		img {
			height: 22px;
		}

		&.round {
			position: relative;
			border-radius: 999px;
			width: 50px;
			height: 50px;
			padding: 0;
			margin: 0;

			@media (max-width: 760px) {
				width: 40px;
				height: 40px;
			}

			img {
				position: absolute;
				height: 50%;
			}
		}

		&.hidden {
			display: none;
		}
		&.invisible {
			pointer-events: none;
			visibility: hidden;
		}

		&.flip {
			transform: rotate(180deg);
		}

		& > * {
			color: inherit;
		}

		&.align-left {
			margin: 15px 0;
		}

		@media only screen and (max-width: 760px) {
			font-size: 14px;
		}

		&.success {
			position: relative;
			background: #29a535;
			padding: 16px 25px 16px 65px;
			img {
				position: absolute;
				height: 40px;
				left: 10px;
			}
			.hoverState {
				opacity: 0;
			}
			&:hover {
				background: #1a8224;
				color: #fff;
				img {
					opacity: 0;
				}
				.hoverState {
					opacity: 1;
				}
			}
		}

		&.shadow {
			filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.25));
		}

		&.primary {
			background: #e20613;
			color: #fff;

			@media (hover: hover) {
				&.hover:hover {
					background: #a50000;
					color: #fff;
				}
			}

			&.disabled {
				background: #a50000;
			}
		}

		&.secondary {
			border: 2px solid #d2d2d2;
			color: #666666;
			background: #fff;

			@media (hover: hover) {
				&.hover:hover {
					border: 2px solid #e20613;
					color: #e20613;
				}
			}

			&.downloading {
				border-color: #e20613;
			}
		}

		&.tertiary {
			color: #1f2836;
			background: #e2e2e2;

			@media (hover: hover) {
				&.hover:hover {
					background: #c2c2c2;
				}
			}

			&.disabled {
				background: #8f8f8f;
			}
		}

		&.black {
			background: #000;
			color: #fff;

			@media (hover: hover) {
				&.hover:hover {
					@apply bg-primary-900;
					color: #fff;
				}
			}

			&.disabled {
				@apply bg-neutral-600;
			}
		}
		&.square {
			margin: 5px 0;
			min-width: 0;
			padding: 5px 10px;
			border-radius: 5px;
			border: 2px solid #e20613;
			color: #e20613;
			font-weight: 400;
		}

		&.transparent {
			color: #333333;
			border: 2px solid transparent;
			background: transparent;

			&.disabled {
				color: #999999;
			}

			&.hover:hover {
				border: 2px solid #e20613;
			}
		}

		&.blank {
			color: #333333;
			border: none;
			background: transparent;

			&.hover:hover {
				border: none;
			}
		}

		&.green {
			background: #179840;

			@media (hover: hover) {
				&:hover {
					background: #147c35;
					color: #fff;
				}
			}
		}

		&.disabled {
			pointer-events: none;

			&.hover:hover {
				cursor: default;
			}
		}

		&.small {
			font-size: 14px;
			padding: 10px 15px;
		}
	}

	.ui-woltair-button[type='submit']:not(.center) {
		margin: 5px 0 5px 0;
	}
</style>
